import { React, createContext, useContext, useState } from 'react'

const defaultState = {
  formView: false,
  modalMode: 'contact',
  isModalOpen: false,
  service: 0,
  offer: 0,
  fleet: 0,
  setFormView: () => {},
  setModalMode: () => {},
  setModalOpen: () => {},
  setService: () => {},
  setOffer: () => {},
  setFleet: () => {},
}

const DataContext = createContext(defaultState)

export const DataProvider = ({ children }) => {
  const [formView, setFormViewState] = useState(false)
  const [modalMode, setModalMode] = useState('contact')
  const [isModalOpen, setModalOpen] = useState(false)
  const [service, setServiceState] = useState(0)
  const [offer, setOfferState] = useState(0)
  const [fleet, setFleetState] = useState(0)

  const setFormView = (value) => setFormViewState(value)
  const setService = (value) => setServiceState(value)
  const setOffer = (value) => setOfferState(value)
  const setFleet = (value) => setFleetState(value)

  const setTeamVideo = (value) => setModalOpen(value)

  return (
    <DataContext.Provider
      value={{
        formView,
        modalMode,
        service,
        offer,
        fleet,
        setFormView,
        setModalMode,
        setService,
        setOffer,
        setFleet,
        setTeamVideo
      }}>
      {children}
    </DataContext.Provider>
  )
}

export default DataContext

export const useDataContext = () => useContext(DataContext)
