import React from 'react'
// import Layout from './src/components/Layout'
import { LangProvider } from './src/context/lang.context'
import { DataProvider } from './src/context/data.context'

import './src/style/style.css'

export const wrapRootElement = ({ element }) => (
  <LangProvider>
    <DataProvider>
      {/* <Layout>{element}</Layout> */}
      {element}
    </DataProvider>
  </LangProvider>
)
