exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-career-js": () => import("./../../../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-carrier-js": () => import("./../../../src/pages/carrier.js" /* webpackChunkName: "component---src-pages-carrier-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dangerous-area-js": () => import("./../../../src/pages/dangerous-area.js" /* webpackChunkName: "component---src-pages-dangerous-area-js" */),
  "component---src-pages-driver-js": () => import("./../../../src/pages/driver.js" /* webpackChunkName: "component---src-pages-driver-js" */),
  "component---src-pages-files-js": () => import("./../../../src/pages/files.js" /* webpackChunkName: "component---src-pages-files-js" */),
  "component---src-pages-fleet-js": () => import("./../../../src/pages/fleet.js" /* webpackChunkName: "component---src-pages-fleet-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industry-js": () => import("./../../../src/pages/industry.js" /* webpackChunkName: "component---src-pages-industry-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-panel-js": () => import("./../../../src/pages/panel.js" /* webpackChunkName: "component---src-pages-panel-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-transport-js": () => import("./../../../src/pages/transport.js" /* webpackChunkName: "component---src-pages-transport-js" */),
  "component---src-pages-transport-quote-js": () => import("./../../../src/pages/transport-quote.js" /* webpackChunkName: "component---src-pages-transport-quote-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-industry-js": () => import("./../../../src/templates/industry.js" /* webpackChunkName: "component---src-templates-industry-js" */),
  "component---src-templates-job-js": () => import("./../../../src/templates/job.js" /* webpackChunkName: "component---src-templates-job-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */),
  "component---src-templates-transport-js": () => import("./../../../src/templates/transport.js" /* webpackChunkName: "component---src-templates-transport-js" */)
}

